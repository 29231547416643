/* .rcw-sender{
    align-items: flex-end;
    background-color: #f4f7f9;
    border-radius: 0 0 10px 10px;
    display: flex;
    height: max-content;
    max-height: 95px;
    min-height: 45px;
    overflow: hidden;
    padding: 10px;
    position: relative;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

.rcw-sender{
    @apply flex justify-between items-center
}

.rcw-launcher img {
    @apply mx-auto w-16 h-16;
    width: 100%; /* set the width and height to 100% to fill the parent container */
    height: 100%;
    object-fit: cover; /* scale the image to fill the container */
}

.rcw-launcher {
    width: 100px;
    height: 100px;
    /* position: fixed;
    right: 100;
    top: 80%; */
    /* top: 50%;
    transform: translateY(-50%); */

    background-color: #fff; /* set the background color to white */
    border-radius: 50%; /* make the element round */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* hide the overflowing content */
}
  

.rcw-launcher svg {
    @apply w-16 h-16;
}

.rcw-launcher svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rcw-close-launcher {
    background-color: rgb(212, 211, 211);
    position: absolute;
    border-radius: 50%;
    padding: 0.2rem;
    width: 40px !important;;
    height: 40px !important;;
  }