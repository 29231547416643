@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body{
        @apply text-[#22343D] box-border
        
    }
}

@layer components{
    .primary-button{
        @apply px-8 py-2 whitespace-nowrap font-semibold text-lg bg-[#02897A] text-white rounded-md cursor-pointer border-[#02897A] hover:bg-white border-2 hover:text-[#02897A] transition-all duration-200 active:scale-90
    }
    .secondary-button{
        @apply px-8 py-2 whitespace-nowrap font-semibold text-lg border-2 border-[#BCD0E5] rounded-md cursor-pointer hover:border-[#02897A] hover:text-[#02897A] duration-200 active:scale-90
    }
    .primary-button-white{
        @apply primary-button bg-white text-primary hover:bg-primary hover:text-white hover:border-white
    }
    .nav-item{
        @apply cursor-pointer px-4 hover:font-semibold hover:scale-105 transition transform active:scale-90
    }
    .container{
        @apply max-w-7xl m-auto px-8 sm:px-12
    }
    .icon-button{
        @apply hover:scale-125 active:scale-95 cursor-pointer duration-200
    }
    
}

body{
    font-family: 'Poppins', sans-serif;
    background-color: rgb(255 255 255);
}